import React from "react";
import { Link } from "react-router-dom";
import SocialMedia from "./SocialMedia";

const StickBar = ({ moreStyle }) => {
  const dateYear = new Date().getFullYear();

  return (
    <div
      className={
        "absolute w-[110px] py-4 space-y-[150px] hidden lg:inline " + moreStyle
      }
    >
      <div
        className="flex flex-none items-center text-white space-y-10 w-full"
        style={{ writingMode: "vertical-rl" }}
      >
        <Link
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          to="/about-us"
        >
          About us
        </Link>

        <Link
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          to="/terms-and-conditions"
        >
          Terms & Conditions
        </Link>

        <Link
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          to="/faq"
        >
          FAQ
        </Link>

        <p className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none">
          <span className="rotate-90">©</span> {dateYear} - The Gallery • V1.0.1
        </p>
      </div>

      <SocialMedia />
    </div>
  );
};

export default StickBar;

import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";

const ImageFullSize = ({ image, state, setSate }) => {
  return (
    <Modal
      open={state}
      onClose={() => {
        setSate(false);
      }}
      className="flex-1 bg-black"
    >
      <div className="relative flex justify-center items-center w-full h-full">
        <img
          className="object-contain w-full lg:max-h-[90%]"
          src={image}
          alt=""
        />

        <div className="absolute top-6 right-6 border-2 border-white rounded-full p-1 cursor-pointer">
          <RxCross2
            className="sm:w-6 sm:h-6 w-7 h-7 cursor-pointer text-white"
            onClick={() => setSate(false)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImageFullSize;

import React, { useRef, useState, useEffect } from "react";
import ArtworksCard from "../components/ArtworksCard";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const Carousel = ({ data }) => {
  const carouselRef = useRef(null);
  const [scrollBarPosition, setScrollBarPosition] = useState("right");
  const [currentItem, setCurrentItem] = useState(1);
  const length = data?.length;

  const handleScrollPrevious = () => {
    if (currentItem > 1) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.clientWidth,
        top: 0,
        behavior: "smooth",
      });

      setCurrentItem((currentItem) => currentItem - 1);
    }
  };

  const handleScrolNext = () => {
    if (currentItem < length) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.clientWidth,
        top: 0,
        behavior: "smooth",
      });

      setCurrentItem((currentItem) => currentItem + 1);
    }
  };

  useEffect(() => {
    if (currentItem === length) {
      setScrollBarPosition("left");
    }

    if (currentItem === 1) {
      setScrollBarPosition("right");
    }
  }, [currentItem]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentItem]);

  return (
    <div className="w-full lg:pt-6">
      {length > 0 ? (
        <div
          className="relative pb-4 md:pb-6 lg:pb-8 w-full overflow-x-auto scroll-smooth inline-flex snap-mandatory snap-x"
          id="container"
          ref={carouselRef}
        >
          {data?.map((artwork, index) => (
            <ArtworksCard key={index} data={artwork} index={index} />
          ))}
        </div>
      ) : (
        <div className="flex w-full min-h-[300px] max-h-[400px] lg:min-h-[400px] lg:max-h-[600px] items-center justify-center">
          <p className="font-source text-[28px] md:text-[36px] text-white">
            No items to display
          </p>
        </div>
      )}

      <div className="flex w-full justify-end pt-2 lg:pt-5 pr-6 md:pr-8 lg:pr-4">
        <div className="flex space-x-2">
          <BsArrowLeft
            className={
              "w-8 h-8 cursor-pointer " +
              (scrollBarPosition === "left" ? " text-[#33c7b6]" : "text-white")
            }
            onClick={handleScrollPrevious}
          />

          <BsArrowRight
            className={
              "w-8 h-8 cursor-pointer " +
              (scrollBarPosition === "right" ? "text-[#33c7b6]" : "text-white")
            }
            onClick={handleScrolNext}
          />
        </div>
      </div>
    </div>
  );
};

export default Carousel;

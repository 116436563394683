import React, { useState } from "react";
import ImageFullSize from "./ImageFullSize";

const ArtworksCard = ({ data, index }) => {
  const [openFullImageModal, setOpenFullImageModal] = useState(false);

  return (
    <>
      <ImageFullSize
        image={data?.image}
        state={openFullImageModal}
        setSate={setOpenFullImageModal}
      />

      <div
        className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-14 flex-none w-full snap-start"
        id={"slide" + (index + 1)}
      >
        <button
          className="flex border-4 border-[#33c7b6] w-full lg:max-w-[420px] xl:max-w-[570px] lg:max-h-[400px] shrink-0 cursor-pointer"
          onClick={() => setOpenFullImageModal(true)}
        >
          <img className="w-full h-full object-" src={data?.image} alt="" />
        </button>

        <div className="flex flex-col space-y-5 ">
          <h1 className="text-[44px]">{data?.title}</h1>

          <p
            className="text-[24px] xl:text-[28px]"
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
          ></p>
        </div>
      </div>
    </>
  );
};

export default ArtworksCard;

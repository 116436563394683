import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig =
  process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === "prod"
    ? process.env.REACT_APP_FIREBASE_CONFIG_PROD
    : process.env.REACT_APP_FIREBASE_CONFIG_STAGING;

const app = initializeApp(JSON.parse(firebaseConfig));
const db = getFirestore(app);

export { db };

import "./Responsive/sm.css";
import "./Responsive/m.css";
import "./Responsive/l.css";
import React, { useState, useEffect, createContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import RegisterWhiteList from "./pages/RegisterWhiteList";
import Register from "./pages/Register";
import Privacy from "./pages/Privacy";
import Home from "./pages/Home";
import Terms from "./pages/TermsAndConditions";
import Faq from "./pages/Faq";
import { AnimatePresence } from "framer-motion";

export const slideContext = createContext();

export const ENVIRONMENT = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT;

export const TG_APP_URL =
  ENVIRONMENT === "prod"
    ? process.env.REACT_APP_TG_APP_URL_PROD
    : process.env.REACT_APP_TG_APP_URL_STAGING;

export const TG_PRIVATE_SALE = TG_APP_URL + "/private-sales";

export const SERVER_URL =
  ENVIRONMENT === "prod"
    ? process.env.REACT_APP_SERVER_URL_PROD
    : ENVIRONMENT === "staging"
    ? process.env.REACT_APP_SERVER_URL_STAGING
    : "http://localhost:3002";

function App() {
  const [number, setNumber] = useState(Math.round(Math.random()));
  const [end, setEnd] = useState(false);
  const [endInscr, setEndInscr] = useState(false);
  const [artistName, setArtistName] = useState("");
  const [loc, setLoc] = useState();
  const location = useLocation();
  const host = window.location.hostname;

  /*useEffect(() => {
    if (host === "the-gallery.io" || host === "www.the-gallery.io") {
      window.gtag("event", "page_view", {
        page_title: location.pathname,
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
      });
    }
  }, [location]);*/

  return (
    <slideContext.Provider
      value={{
        end,
        setEnd,
        endInscr,
        setEndInscr,
        number,
        setNumber,
        artistName,
        setArtistName,
        loc,
        setLoc,
      }}
    >
      <div className="App max-w-[1440px] mx-auto">
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register-whitelist" element={<RegisterWhiteList />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AnimatePresence>
      </div>
    </slideContext.Provider>
  );
}

export default App;

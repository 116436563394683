import React, { useEffect, useState } from "react";
import Close from "../components/Close";
import validator from "validator";
import { BsCircleFill } from "react-icons/bs";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router-dom";

const RegisterWhiteList = () => {
  const { state } = useLocation();
  const [error, setError] = useState("");
  const [errorSuccess, setErrorSuccess] = useState("");
  const [contactInformations, setContactInformations] = useState({
    firstname: state?.name ? state?.name : "",
    lastName: "",
    mail: "",
    phone: "",
    haveWallet: false,
    havePurchased: false,
    isPassionate: false,
    permission: false,
    address: "",
  });

  useEffect(() => {
    return window.scrollTo(0, 0);
  }, [errorSuccess]);

  const sendSubscription = () => {
    if (contactInformations.mail !== "") {
      if (validator.isEmail(contactInformations.mail) === true) {
        fetch(
          `https://v70ydcjnm9.execute-api.eu-west-1.amazonaws.com/whitelist`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(contactInformations),
          }
        )
          .then((response) => response.json())
          .then((res) => {
            setErrorSuccess("200");
          })
          .catch((error) => {
            console.log(error);
            setErrorSuccess("400");
          });
      } else {
        setError("Email address is invalid.");
      }
    } else {
      setError("Please complete all required fields");
    }
  };

  useEffect(() => {
    if (contactInformations?.address !== "") {
      setContactInformations({ ...contactInformations, haveWallet: true });
    } else {
      setContactInformations({ ...contactInformations, haveWallet: false });
    }
  }, [contactInformations?.address]);

  return (
    <div>
      <div className="relative h-[200px] sm:h-[300px] w-full p-2 sm:p-4">
        <img
          className="object-cover h-full w-full"
          src="/images/privacy.png"
          alt="page cover"
        />

        <Close />
      </div>

      <div className="flex justify-center text-white -mt-28 sm:-mt-32 md:-mt-44 z-50">
        <div className="space-y-14 w-[90%] md:w-[80%] lg:w-[780px]">
          <div className="relative flex space-x-10 pl-8 md:pl-0">
            <div className="flex-none bg-white rounded-full w-1.5 h-44 md:h-52"></div>

            <p className="absolute top-10 text-[42px] md:text-[54px] text-white">
              Subscribe to the whitelist
            </p>
          </div>

          <div className="space-y-14 pl-0 md:pl-16">
            <p className="text-xl font-semibold">
              Our first exclusive drop sale will last 72 hours, with the first
              day dedicated to our whitelist users. Register here, to get a free
              slot to the whitelisted and get a chance to win an exclusive gift*
            </p>

            {errorSuccess === "200" ? (
              <div>
                <p className="text-[#33c7b6] text-xl">
                  Thank you! Your submission has been sent.
                </p>
              </div>
            ) : errorSuccess === "400" ? (
              <div>
                <p className="text-red-500 text-xl">
                  An error has occurred please try again.
                </p>
              </div>
            ) : null}

            <div className="space-y-5">
              <h5 className="text-[24px] font-bold">My contact informations</h5>

              <div className="space-y-6 md:space-y-0 md:flex md:space-x-8">
                <div className="w-full font-source">
                  <label className="">My first name</label>

                  <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                    <input
                      type="text"
                      className="outline-none bg-transparent w-full"
                      placeholder={state?.name ? state?.name : "Ex: Julien"}
                      onChange={(e) =>
                        setContactInformations({
                          ...contactInformations,
                          firstname: e.target.value,
                        })
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Ex: Julien")}
                    />
                  </div>
                </div>

                <div className="w-full font-source">
                  <label className="">My last name</label>

                  <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                    <input
                      type="text"
                      className="outline-none bg-transparent w-full"
                      placeholder="Ex: Dupond"
                      onChange={(e) =>
                        setContactInformations({
                          ...contactInformations,
                          lastName: e.target.value,
                        })
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Ex: Dupond")}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-6 md:space-y-0 md:flex md:space-x-8">
                <div className="w-full font-source">
                  <label className="">My email*</label>

                  <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                    <input
                      type="mail"
                      className="outline-none bg-transparent w-full"
                      placeholder="Ex: j.dupond@studioarts.com"
                      onChange={(e) =>
                        setContactInformations({
                          ...contactInformations,
                          mail: e.target.value,
                        })
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Ex: j.dupond@studioarts.com")
                      }
                    />
                  </div>
                </div>

                <div className="w-full font-source">
                  <label className="">My phone number</label>

                  <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                    <input
                      type="text"
                      className="outline-none bg-transparent w-full"
                      placeholder="Ex: +33 6 12 34 56 78"
                      onChange={(e) =>
                        setContactInformations({
                          ...contactInformations,
                          phone: e.target.value,
                        })
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Ex: +33 6 12 34 56 78")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="w-full font-source">
                <label className="">My wallet address</label>

                <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                  <input
                    type="mail"
                    className="outline-none bg-transparent w-full"
                    placeholder="Ex: 0xcA6120f88e01B0f332Fed3F2120f885D0"
                    onChange={(e) =>
                      setContactInformations({
                        ...contactInformations,
                        address: e.target.value,
                      })
                    }
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Ex: 0xcA6...01B0")}
                  />
                </div>

                {error && !errorSuccess && (
                  <div>
                    <p className="text-sm text-red-500 mt-2">{error}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <h5 className="text-[24px] font-bold">My motivations</h5>

              <div className="font-source">
                <div className="flex py-4 border-b-2 space-x-2 border-white items-center justify-between">
                  <p className="">I already have a wallet account</p>

                  {contactInformations.haveWallet ? (
                    <button
                      className="flex border-2 border-[#33c7b6] rounded-full p-2 items-center text-[#33c7b6]"
                      onClick={() =>
                        setContactInformations({
                          ...contactInformations,
                          haveWallet: false,
                        })
                      }
                    >
                      <span className="px-2">Yes</span>

                      <BsCircleFill className="w-6 h-6 " />
                    </button>
                  ) : (
                    <button
                      className="flex border-2 border-white rounded-full p-2 items-center"
                      onClick={() =>
                        setContactInformations({
                          ...contactInformations,
                          haveWallet: true,
                        })
                      }
                    >
                      <BsCircleFill className="w-6 h-6" />

                      <span className="px-2">No</span>
                    </button>
                  )}
                </div>

                <div className="flex py-4 border-b-2 space-x-2 border-white items-center justify-between">
                  <p className="">
                    I have already purchased works through an nft platform
                  </p>

                  {contactInformations.havePurchased ? (
                    <button
                      className="flex border-2 border-[#33c7b6] rounded-full p-2 items-center text-[#33c7b6]"
                      onClick={() =>
                        setContactInformations({
                          ...contactInformations,
                          havePurchased: false,
                        })
                      }
                    >
                      <span className="px-2">Yes</span>

                      <BsCircleFill className="w-6 h-6 " />
                    </button>
                  ) : (
                    <button
                      className="flex border-2 border-white rounded-full p-2 items-center"
                      onClick={() =>
                        setContactInformations({
                          ...contactInformations,
                          havePurchased: true,
                        })
                      }
                    >
                      <BsCircleFill className="w-6 h-6" />

                      <span className="px-2">No</span>
                    </button>
                  )}
                </div>

                <div className="flex py-4 border-b-2 space-x-2 border-white items-center justify-between">
                  <p className="">I am passionate about art</p>

                  {contactInformations.isPassionate ? (
                    <button
                      className="flex border-2 border-[#33c7b6] rounded-full p-2 items-center text-[#33c7b6]"
                      onClick={() =>
                        setContactInformations({
                          ...contactInformations,
                          isPassionate: false,
                        })
                      }
                    >
                      <span className="px-2">Yes</span>

                      <BsCircleFill className="w-6 h-6 " />
                    </button>
                  ) : (
                    <button
                      className="flex border-2 border-white rounded-full p-2 items-center"
                      onClick={() =>
                        setContactInformations({
                          ...contactInformations,
                          isPassionate: true,
                        })
                      }
                    >
                      <BsCircleFill className="w-6 h-6" />

                      <span className="px-2">No</span>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="flex space-x-2.5 font-source">
              <Checkbox
                sx={{
                  color: "#FFFFFF",
                  "&.Mui-checked": {
                    color: "#FFFFFF",
                  },
                }}
                onClick={(event) =>
                  setContactInformations({
                    ...contactInformations,
                    permission: event.target.checked,
                  })
                }
              />

              <p className="texte-[14px] md:texte-[17px]">
                I hereby give permission to TheGallery to use my data for the
                purpose documented and under conditions stated in ourPrivacy
                Agreement
              </p>
            </div>

              <div className="flex w-full justify-center font-source pb-16">
                <button
                  className="border-2 border-[#33c7b6] text-[#33c7b6] rounded-full py-2 px-8"
                  onClick={sendSubscription}
                >
                  Register
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterWhiteList;

import React, { useRef, useState } from "react";
import { BsPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";

const VideoPlayer = ({ path, poster }) => {
  const [startVideo, setStartVideo] = useState(false);
  //const [buttonHidden, setButtonHidden] = useState(true);
  const videoRef = useRef(null);

  const handlePlayPauseVideo = () => {
    setStartVideo(!startVideo);

    if (startVideo) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  return (
    <div className="w-full">
      <div
        className="relative w-full h-full "
        //onMouseEnter={() => setButtonHidden(true)}
        //onMouseLeave={() => setButtonHidden(false)}
      >
        <video
          ref={videoRef}
          src={path}
          poster={poster}
          className="w-full h-full object-cover"
        />

        <div className="flex absolute left-0 right-0 top-0 bottom-0 items-center justify-center">
          {startVideo ? (
            <BsFillPauseCircleFill
              className="w-16 h-16 text-white cursor-pointer"
              onClick={handlePlayPauseVideo}
            />
          ) : (
            <BsPlayCircleFill
              className="w-16 h-16 text-white cursor-pointer"
              onClick={handlePlayPauseVideo}
            />
          )}
        </div>
      </div>

      {startVideo ? (
        <div
          className="flex space-x-3 items-center w-full pt-3 cursor-pointer"
          onClick={handlePlayPauseVideo}
        >
          <BsFillPauseCircleFill className="w-6 h-6 text-white cursor-pointer" />

          <p className="">Stop video</p>
        </div>
      ) : (
        <div
          className="flex space-x-3 items-center w-full pt-3 cursor-pointer"
          onClick={handlePlayPauseVideo}
        >
          <BsPlayCircleFill className="w-6 h-6 text-white cursor-pointer" />

          <p className="">Play video</p>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;

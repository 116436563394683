import React, { useEffect } from "react";
import "./Privacy.css";
import Close from "../components/Close.js";
import PrivacyContent from "../components/PrivacyContent";

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="relative h-[200px] sm:h-[300px] w-full p-2 sm:p-4">
        <img
          className="object-cover h-full w-full"
          src="/images/privacy.png"
          alt="page cover"
        />

        <Close />
      </div>

      <div className="flex justify-center text-white -mt-28 sm:-mt-32 md:-mt-44 z-50">
        <div className="space-y-14 w-[90%] md:w-[80%] lg:w-[780px]">
          <div className="relative flex space-x-10 pl-8 md:pl-0">
            <div className="flex-none bg-white rounded-full w-1.5 h-44 md:h-52"></div>

            <p className="absolute top-10 text-[42px] md:text-[54px] text-white">
              Privacy Policy
            </p>
          </div>

          <div className="text-white pl-0 md:pl-16 pb-14">
            <PrivacyContent />
          </div>
        </div>
      </div>
    </div>
  );
}

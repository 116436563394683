import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import StickBar from "../components/StickBar";
import Header from "../components/Header";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      className="relative"
      initial={{ width: 0, opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 }, opacity: 0 }}
    >
      <Header logo={true} />

      <div className="flex ">
        <StickBar />

        <div className="w-full lg:pl-[110px]">
          <div className="lg:flex pt-16 md:pt-8 px-6 md:px-8 lg:px-4 lg:space-x-16">
            <div className="w-[55%] sm:w-[40%] lg:w-[35%] xl:w-[30%]">
              <div className="-z-50 relative border-[4px] h-[320px] md:h-[500px] xl:h-[600px] border-[#33c7b6]">
                <img
                  className="absolute top-6 left-6 w-full h-full object-cover"
                  src="/images/aboutUs.png"
                  alt=""
                />
              </div>
            </div>

            <div className="text-white w-full lg:w-[65%] xl:w-[70%] -mt-44 lg:mt-0">
              <div className="relative flex space-x-10 pl-14 lg:pl-0">
                <div className="flex-none bg-white rounded-full w-1.5 h-60"></div>

                <p className="absolute bottom-16 lg:bottom-12 text-[42px] md:text-[54px] text-white">
                  Terms & conditions
                </p>
              </div>

              <div className="space-y-14 py-16 pl-6">
                <div className="space-y-6">
                  <p className="text-[36px] font-medium">Who we are</p>

                  <p className="text-2xl font-medium">
                    TheGallery is a registered trademark, owned by TheGallery
                    SAS company (SIREN 948721402). TheGallery company aims to
                    promote digital art, to create value for artists and to
                    foster and develop an ecosystem where art lovers and
                    collectors can gather and share about their passion.
                    TheGallery is achieving this through its NFT plateforme,
                    dedicated to photography and fine digital art, empowering
                    artists to create, own and develop their artwork while
                    allowing them to get the best value out of it.
                  </p>
                </div>

                <div className="space-y-6 text-xl font-medium">
                  <p className="text-[36px] font-medium">Contact us</p>

                  <p className="text-2xl font-medium">
                    You can contact TheGallery team any time by email :{" "}
                    <a href="mailto:tg-contact@the-gallery.io">
                      tg-contact@the-gallery.io
                    </a>
                    .
                  </p>
                </div>

                <div className="space-y-6">
                  <p className="text-[36px] font-medium">Site hosting</p>

                  <p className="text-2xl font-medium">
                    The site is hosted by Amazon Web Services
                    <ul>
                      <li>
                        • Amazon Web Services EMEA, SARL, Inc. P.O. Box 81226
                        Seattle, WA 98108-1226
                      </li>
                      <li>• 38 Avenue John F.Kennedy, L-1855, Luxembourg</li>
                      <li>• R.C.S. Luxembourg : B186284 </li>
                    </ul>
                  </p>
                </div>

                <div className="space-y-6">
                  <p className="text-[36px] font-medium">Privacy Statement</p>

                  <p className="text-2xl font-medium">
                    Because we value your data privacy TheGallery is taking all
                    necessary steps in order to protect and secure them. Please
                    read more,{" "}
                    <Link to="/privacy" className="underline">
                      click here.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

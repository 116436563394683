import React, { useEffect } from "react";
import StickBar from "../components/StickBar";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header logo={true} />

      <div className="flex ">
        <StickBar />

        <div className="w-full lg:pl-[110px]">
          <div className="lg:flex pt-16 md:pt-8 px-6 md:px-8 lg:px-4 lg:space-x-16">
            <div className="w-[55%] sm:w-[40%] lg:w-[35%] xl:w-[30%]">
              <div className="-z-50 relative border-[4px] h-[320px] sm:h-[400px] md:h-[500px] xl:h-[600px] border-[#33c7b6]">
                <img
                  className="absolute top-8 left-8 w-full h-full object-cover"
                  src="/images/aboutUs.png"
                  alt=""
                />
              </div>
            </div>

            <div className="text-white w-full lg:w-[65%] xl:w-[70%] -mt-44 lg:mt-0">
              <div className="relative flex space-x-10 pl-14 lg:pl-0">
                <div className="flex-none bg-white rounded-full w-1.5 h-60"></div>

                <p className="absolute bottom-12 text-[42px] md:text-[54px] text-white">
                  About us
                </p>
              </div>

              <div className="space-y-14 py-16 pl-6">
                <div className="">
                  <p className="text-center text-[28px] lg:text-[36px] font-medium">
                    TheGallery was born with the objective to gather a community
                    of artists and collectors around their passion: the
                    photography.
                  </p>
                </div>

                <div className="space-y-6 text-2xl font-medium">
                  <p>
                    Our focus is to ensure creators are getting the most out of
                    their art, by promoting what they do and giving them the
                    opportunity to share it with collectors and other artists
                    around the world. Thanks to the blockchain technology,
                    artists can now remain in control of their art, while being
                    able to safely promote and share it with their fans and
                    community.
                  </p>

                  <p>
                    Because, we love what we do, our values are driving our
                    decisions and the way we built TheGallery.
                  </p>

                  <p>
                    Be-it on Quality, Fairness, Diversity or Transparency; we
                    strongly support those values which represent at best what
                    will allow us to make TheGallery the best place to be for
                    photographers of the world.
                  </p>
                </div>

                <div className="space-y-6">
                  <p className="text-[36px] font-medium">FAQ</p>

                  <p className="text-2xl font-medium">
                    Do you have any questions ? Find your answers{" "}
                    <Link
                      to={`/faq`}
                      className="text-2xl font-medium underline cursor-pointer"
                    >
                      here
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import React, { useRef } from "react";

export default function PrivacyContent() {
  const contextRef = useRef();
  const scopeRef = useRef();
  const dataCollecRef = useRef();
  const dataCollecDirRef = useRef();
  const dataCollecIndRef = useRef();
  const cookiesRef = useRef();
  const purposeRef = useRef();
  const dataRecRef = useRef();
  const dataRetRef = useRef();
  const securityRef = useRef();
  const accessRef = useRef();
  const claimsRef = useRef();
  const modifsRef = useRef();

  const scrollTol = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  };

  return (
    <div id="privacy-ctn">
      <div id="table-ctn">
        <ol>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(contextRef);
              }}
            >
              1. Context
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(scopeRef);
              }}
            >
              2. Scope and limitations
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(dataCollecRef);
              }}
            >
              3. Data collection
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(dataCollecDirRef);
              }}
            >
              4. Data collected directly
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(dataCollecIndRef);
              }}
            >
              5. Data collected indirectly
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(cookiesRef);
              }}
            >
              6. Cookies
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(purposeRef);
              }}
            >
              7. Purpose and use of the data collected
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(dataRecRef);
              }}
            >
              8. Data recipients
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(dataRetRef);
              }}
            >
              9. Data retention period
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(securityRef);
              }}
            >
              10. Security of your data
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(accessRef);
              }}
            >
              11. Rights of access, correction, limitation and deletion of your
              data
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(claimsRef);
              }}
            >
              12. Claims
            </button>
          </li>
          <li>
            <button
              className="text-justify"
              onClick={() => {
                scrollTol(modifsRef);
              }}
            >
              13. Modification of privacy policy
            </button>
          </li>
        </ol>
      </div>
      <div id="priv-body">
        <section ref={contextRef} id="context" className="priv-section">
          <p className="section-title">Context</p>
          <p className="section-body">
            By using and accessing the <strong>TheGallery</strong> website{" "}
            <strong>
              <a href="https://the-gallery.io">https://the-gallery.io</a>
            </strong>
            , hereinafter identified as “Site”, you agree to the collection and
            use of your personal data, hereinafter identified as "Data", in
            accordance with the procedures and usage described in this Personal
            Data Confidentiality Policy, hereinafter "Confidentiality Policy".
            In the event of a revision, the Privacy Policy will be updated on
            the Site. You are therefore invited to come and consult it
            regularly. Because we value your privacy and data protection,{" "}
            <strong>TheGallery</strong> will take all necessary actions to
            securely store and use your data and ensure its integrity.
            <strong>TheGallery</strong> Site, therefore will collect, store and
            process your data in accordance with the applicable laws and
            regulations and in particular the European Regulation on the
            protection of personal data of April 27, 2016 and the Data
            Protection Act of January 6, 1978 as amended, hereinafter identified
            as “GDPR”.
          </p>
        </section>

        <section ref={scopeRef} id="scope" className="priv-section">
          <p className="section-title">Scope and limitations</p>
          <p className="section-body">
            The Privacy Policy applies to all processing of personal data
            personnel carried out by <strong>TheGallery</strong> from the Site.
            Conversely, the Privacy Policy does not apply to processing carried
            out on third party websites or applications for which{" "}
            <strong>TheGallery</strong> is not the processing, managing or
            storing Data, even if said sites or applications are mentioned on
            the Site.
          </p>
        </section>

        <section ref={dataCollecRef} id="data-collec" className="priv-section">
          <p className="section-title">Data collection</p>
          <p className="section-body">
            The Data are "personal data" or any data as defined by the article 4
            of the GDPR, that is to say any information allowing the direct or
            indirect identification of a natural person, such as last name,
            first name, age, IP address, email address, location data, elements
            specific to their physical, physiological, genetic, psychological,
            economic, cultural or social identity.{" "}
          </p>
        </section>

        <section
          ref={dataCollecDirRef}
          id="data-collec-dir"
          className="priv-section"
        >
          <p className="section-title">Data collected directly</p>
          <p className="section-body">
            This is the Data provided to us directly through the contact form or
            profile that you accepted to fill-in in order to be informed about
            the project. This Data, includes your contact details in order for{" "}
            <strong>TheGallery</strong> to provide personalized information
            based on your profile (collector, artist or agent’s artist).
            <p className="section-subtitle">As a collector</p>
            <ul>
              <li>• First name and last name </li>
              <li>• Email address of contact</li>
            </ul>
            <p className="section-subtitle">As an agent or artist</p>
            <ul>
              <li>• First name and last name</li>
              <li>• Artist name</li>
              <li>• Artist book website</li>
              <li>• Phone number</li>
              <li>• Email address of contact</li>
            </ul>
          </p>
        </section>

        <section
          ref={dataCollecIndRef}
          id="data-collec-ind"
          className="priv-section"
        >
          <p className="section-title">Data collected indirectly</p>
          <p className="section-body">
            No indirect data is collected on <strong>TheGallery</strong> Site.
          </p>
        </section>

        <section ref={cookiesRef} id="cookies" className="priv-section">
          <p className="section-title">Cookies</p>
          <p className="section-body">
            This website uses cookies. We use cookies to personalise content and
            ads, to provide social media features and to analyse our traffic. We
            also share information about your use of our site with our social
            media, advertising and analytics partners who may combine it with
            other information that you’ve provided to them or that they’ve
            collected from your use of their services. Cookies are small text
            files that can be used by websites to make a user's experience more
            efficient. The law states that we can store cookies on your device
            if they are strictly necessary for the operation of this site. For
            all other types of cookies we need your permission. This site uses
            different types of cookies. Some cookies are placed by third party
            services that appear on our pages. You can at any time change or
            withdraw your consent from the Cookie Declaration on our website.
            Learn more about who we are, how you can contact us and how we
            process personal data in our Privacy Policy or by email
            tg-contact@the-gallery.io Cookie declaration last updated on
            25/04/2023 by Cookiebot.
          </p>
        </section>

        <section ref={purposeRef} id="purpose" className="priv-section">
          <p className="section-title">
            Purpose and use of the data collected{" "}
          </p>
          <p className="section-body">
            <strong>TheGallery</strong> collects, through your direct input, the
            minimum set of data required to provide useful information to
            collectors, artists, and agent’s artists, and ensure a smooth
            operation of <strong>TheGallery</strong> Site.
            <p className="section-subtitle">As a collector :</p>
            <p>
              We will use your data in order to send you regular information
              about <strong>TheGallery</strong> project, like news, artists
              on-boarded, key milestones reached and launch dates for drops.
              This information will be available on <strong>TheGallery</strong>{" "}
              Site and you will be notified by email.
            </p>
            <p className="section-subtitle">As an agent or artist :</p>
            <p>
              We will use your data in order to send you regular information
              about <strong>TheGallery</strong> project, like news, artists
              on-boarded, key milestones reached and launch dates for drops.
              This information will be available on <strong>TheGallery</strong>{" "}
              Site and you will be notified by email. We will also use your
              contact details in order assess your willingness to join{" "}
              <strong>TheGallery</strong> artists list of contributors. This
              contact will be done by email and or phone number depending on the
              context and will only be carried out with your consent. Moreover,
              with the legitimate aim for <strong>TheGallery</strong> to develop
              its services and maintain its relationship with collectors,
              artists and agent’s artist the processing of Data also has the
              following purposes:
              <ul>
                <li>• Sending information and newsletters;</li>
                <li>
                  • Sending invitation to events organized by{" "}
                  <strong>TheGallery</strong> or its partners;
                </li>
              </ul>
              With the legitimate aim of <strong>TheGallery</strong> to ensure
              the security of the Site and improve its operation, Data
              processing allows:
              <ul>
                <li>• Carry out anonymized statistical calculations;</li>
                <li>
                  • Examine complaints, problems, bugs generated by the Site, in
                  order to resolve them;
                </li>
                <li>
                  • To ensure that the use of the Site complies with the General
                  Conditions of use
                </li>
                <li>• To detect possible computer abuse and malicious acts.</li>
              </ul>
            </p>
          </p>
        </section>

        <section ref={dataRecRef} id="data-rec" className="priv-section">
          <p className="section-title">Data recipients</p>
          <p className="section-body">
            Your Data is reserved for the exclusive use of{" "}
            <strong>TheGallery</strong> and cannot be communicated, even free of
            charge, to any third parties. However, <strong>TheGallery</strong>{" "}
            may be required to transfer all or part of this Data to trusted
            third parties who process them on its behalf, according to its
            instructions, in accordance with the Privacy Policy and in
            compliance with the GDPR and the Data Protection Act.
          </p>
        </section>

        <section ref={dataRetRef} id="data-ret" className="priv-section">
          <p className="section-title">Data retention period</p>
          <p className="section-body">
            <strong>TheGallery</strong> only keeps your Data within the limits
            imposed by law. For the purpose of its operations, this period will
            usually not exceed 3 years. If legally required, your Data could be
            archived for more than 3 years in a secured and anonymous way.
          </p>
        </section>

        <section ref={securityRef} id="security" className="priv-section">
          <p className="section-title">Security of your data</p>
          <p className="section-body">
            <strong>TheGallery</strong> strives to keep the personal data it
            processes securely and only for the time necessary to achieve the
            purpose of the processing. In this perspective,{" "}
            <strong>TheGallery</strong> takes the appropriate physical,
            technical and organizational measures to prevent and prevent, as far
            as possible, any alteration, loss of your data or any unauthorized
            access to them. If <strong>TheGallery</strong> learns that a third
            party to which it has communicated your Data under the conditions
            provided for in Article 4, uses or discloses them in violation of
            the Confidentiality Policy or in violation of its commitments or of
            the applicable laws and regulations, it will take any measure to
            immediately end such use.
          </p>
        </section>

        <section ref={accessRef} id="access-rights" className="priv-section">
          <p className="section-title">
            Rights of access, correction, limitation and deletion of your data
          </p>
          <p className="section-body">
            In accordance with applicable law, you always have the right to
            access, modify or ask us to delete your Data. By contacting{" "}
            <strong>TheGallery </strong>operations team you can request to:
            <ul>
              <li>
                • Correct, complete, update or delete your inaccurate,
                incomplete or outdated Data;
              </li>
              <li>
                • Request the limitation of the processing of your data for a
                legitimate reason;
              </li>
              <li>
                • Oppose the processing of your Data for a legitimate reason;
              </li>
              <li>• Request the deletion of your Data.</li>
            </ul>
            You can also at any time and without reason, object to the
            processing of your Data for communication purposes like newsletters
            and project update information. You can exercise your rights please
            send an email to{" "}
            <strong>
              <button
                onClick={() =>
                  (window.location = "mailto:tg-contact@the-gallery.io")
                }
              >
                tg.contact@the-gallery.io
              </button>
            </strong>{" "}
            . We will review your demand and process it within 5 working days.
          </p>
        </section>

        <section ref={claimsRef} id="claims" className="priv-section">
          <p className="section-title">Claims</p>
          <p className="section-body">
            If you believe that the security of your Data has been compromised
            or that these Data have been misused, you are invited to contact{" "}
            <strong>TheGallery </strong>operation team at the earliest by email
            to{" "}
            <strong>
              <button
                onClick={() =>
                  (window.location = "mailto:tg-contact@the-gallery.io")
                }
              >
                tg.contact@the-gallery.io.
              </button>
            </strong>
            <strong>
              <br />
              TheGallery
            </strong>{" "}
            operation team will investigate your complaint as soon as possible
            and will try to find a solution in accordance with the regulations
            in force.
          </p>
        </section>

        <section ref={modifsRef} id="modifs" className="priv-section last">
          <p className="section-title">Modification of privacy policy</p>
          <p className="section-body">
            <strong>TheGallery</strong> reserves the right to modify or
            supplement the Privacy Policy at any time, especially to adapt to
            changing regulations.
          </p>
        </section>
      </div>
    </div>
  );
}

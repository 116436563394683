import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { TG_APP_URL } from "../App";
import SocialMedia from "./SocialMedia";

const Header = ({ logo, isVisible = true }) => {
  const [openBurgerMenuModal, setOpenBurgerMenuModal] = useState(false);
  const dateYear = new Date().getFullYear();

  const scrollTo = () => {
    var element = document.getElementById("newsletter");

    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <>
      <div className="sticky top-0 z-40">
        <div
          className={`flex w-full space-x-4 z-40 px-6 md:px-8 lg:px-4 h-[70px] md:h-[100px] justify-between items-center bg-[#00263a]`}
        >
          <div className="flex space-x-3 lg:space-x-0 items-center">
            <AiOutlineMenu
              className="flex lg:hidden w-8 h-8 md:w-9 md:h-9 text-white cursor-pointer"
              onClick={() => setOpenBurgerMenuModal(true)}
            />

            {isVisible && (
              <div
                className={`transition-opacity duration-700 ${
                  isVisible ? "opacity-100" : "opacity-0 invisible"
                }`}
              >
                <Link to="/">
                  <img
                    src="/images/logo.png"
                    className="w-[120px] md:w-[160px] lg:w-[180px] h-auto"
                    alt=""
                  />
                </Link>
              </div>
            )}
          </div>

          <div className="flex items-center space-x-2 md:space-x-3">
            <button
              onClick={scrollTo}
              className="bg-white rounded-full text-[#0B2533] font-source font-medium px-3 sm:px-7 py-3 hover:text-[#33c7b6]"
            >
              Newsletter
            </button>

            <a
              href={TG_APP_URL}
              target="_blank"
              rel="noreferrer"
              className="bg-[#33c7b6] rounded-full text-[#0B2533] font-source font-medium px-3 sm:px-7 py-3 hover:text-white"
            >
              Artworks
            </a>
          </div>

          <Modal
            open={openBurgerMenuModal}
            onClose={() => {
              setOpenBurgerMenuModal(false);
            }}
            className="flex lg:hidden"
          >
            <div className="flex flex-col bg-[#00263a] w-full md:w-[300px] h-full outline-none">
              <div className="flex justify-start md:justify-end items-center p-4">
                <RxCross2
                  className="w-11 h-11 cursor-pointer text-white"
                  onClick={() => {
                    setOpenBurgerMenuModal(false);
                  }}
                />
              </div>

              <div className="flex flex-col py-10 px-6 space-y-10 w-full text-white">
                <div className="w-full items-center">
                  <Link to="/">
                    <img className="w-40" src="/images/logo.png" alt="" />
                  </Link>
                </div>

                <Link
                  className="text-lg text-[15px] sm:text-[16px]"
                  to="/about-us"
                >
                  About us
                </Link>

                <Link
                  className="text-lg text-[15px] sm:text-[16px]"
                  to="/terms-and-conditions"
                >
                  Terms & Conditions
                </Link>

                <Link className="text-lg text-[15px] sm:text-[16px]" to="/faq">
                  FAQ
                </Link>

                <p className="text-lg text-[15px] sm:text-[16px]">
                  © {dateYear} - The Gallery • V1.0.1
                </p>

                <SocialMedia />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Header;

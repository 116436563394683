import React from "react";
import { useNavigate } from "react-router-dom";

export default function Close(props) {
  const navigate = useNavigate();

  const goHome = async () => {
    navigate(-1);
  };

  return (
    <div onClick={goHome} className="absolute top-8 sm:top-12 right-12 md:right-32 xl:right-52">
      <div className="w-[80px] h-[80px] hover:scale-110 ease-in duration-100 cursor-pointer">
        <img className="w-full h-full" src="/images/BT-Cancel.png" alt="close" />
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import StickBar from "../components/StickBar";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import Header from "../components/Header";
import useScrollPosition from "../hooks/useScrollPosition";

const Faq = () => {
  const scroll = useScrollPosition();
  const [faq, setFaq] = useState([]);
  const questionRef = useRef(null);

  useEffect(() => {
    const getQuestions = async () => {
      let questions = [];

      const collRef = collection(db, "faq");

      const querySnapshot = await getDocs(collRef);

      querySnapshot.forEach((doc) => {
        questions.push(doc.data());
      });

      setFaq(questions);
    };

    getQuestions();
  }, []);

  function scrollTo(id) {
    document.getElementById("question-" + id)?.scrollIntoView({ block: "end" });
  }

  return (
    <div>
      <Header logo={true} />

      <div className="flex">
        <StickBar />

        <div className="w-full lg:pl-[110px] pt-8 md:pt-14">
          <div className="w-full text-white px-6 md:px-8 lg:px-4">
            <div className="space-y-4">
              <h1>F.A.Q</h1>

              <div className="bg-white rounded-full h-1 w-full"></div>
            </div>

            <div className="lg:flex lg:space-x-24 xl:space-x-36 py-14 space-y-20">
              <div className="flex-none space-y-10">
                <h3 className="font-medium">Contents</h3>

                <ul className="w-full lg:max-w-[310px] font-source space-y-6 text-base pl-9">
                  {faq?.map((elem, index) => (
                    <li
                      className="cursor-pointer hover:text-[#33c7b6]"
                      key={elem.question}
                      onClick={() => scrollTo(index + 1)}
                    >
                      • {elem.question}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="space-y-14">
                {faq?.map((elem, index) => (
                  <div
                    className="space-y-5"
                    ref={questionRef[index]}
                    id={"question-" + (index + 1)}
                    key={elem.question}
                  >
                    <h3 className="font-medium" key={elem.question}>
                      • {elem.question}
                    </h3>

                    <p className="font-source text-base" key={elem.answer}>
                      {elem.answer}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
